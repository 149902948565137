<template>
  <div id="app">
    <div class="fixed">
      <navigation />
      <router-view></router-view>
      <Services />
      <!-- <Merch /> -->
      <Footer />
    </div>
  </div>
</template>

<script>
import Navigation from "./components/Navigation.vue";
import Footer from "./components/Footer.vue";
import Services from "./components/Services.vue";
// import CurvedBox from "./components/CurvedBox.vue"
// import Merch from "./components/Merch.vue";
export default {
  components: { Navigation, Footer, Services },
  data() {
    return {};
  },
  created() {},
  computed: {
    url() {
      return this.$store.state.url;
    },
  },
  methods: {},
};
</script>

<style>
/* #studio, #merch {
 scroll-behavior: smooth ;
} */
.freeze {
  overflow: hidden;
}

body {
  overflow-x: hidden;
}
html {
  scroll-behavior: smooth;
}
* {
  margin: 0;
  padding: 0;
}

.fixed {
  max-width: 1440px;
  margin: 0 auto;
}

/* @media screen and (max-width: 1366px) {
  .fixed {
    max-width: 1300px;
  }
} */
@media screen and (max-width: 768px) {
  .fixed {
    width: 100%;
  }
}

@font-face {
  font-family: "Calibri-BoldItalic";
  src: url(./assets/fonts/Calibri-BoldItalic.woff2) format("woff2"),
    url(./assets/fonts/Calibri-BoldItalic.woff) format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Calibri-Italic";
  src: url(./assets/fonts/Calibri-Italic.woff2) format("woff2"),
    url(./assets/fonts/Calibri-Italic.woff) format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Calibri-Bold";
  src: url(./assets/fonts/Calibri-Bold.woff2) format("woff2"),
    url(./assets/fonts/Calibri-Bold.woff) format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Calibri-LightItalic";
  src: url(./assets/fonts/Calibri-LightItalic.woff2) format("woff2"),
    url(./assets/fonts/Calibri-LightItalic.woff) format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Calibri-Regular";
  src: url(./assets/fonts/Calibri.woff2) format("woff2"),
    url(./assets/fonts/Calibri.woff) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Calibri-Light";
  src: url(./assets/fonts/Calibri-Light.woff2) format("woff2"),
    url(./assets/fonts/Calibri-Light.woff) format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
</style>
