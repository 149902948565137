import Vue from "vue";
import Router from "vue-router";

import Home from "./pages/Home.vue";
import MainLayout from "./layouts/Main.vue";
import About from "./pages/About.vue";
import PageDetailed from "./pages/PageDetailed.vue";
import StudioDetailed from "./pages/StudioDetailed.vue";
import AgencyDetailed from "./pages/AgencyDetailed.vue";
import ServicesDetailed from "./pages/ServicesDetailed.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: MainLayout,
      children: [
        {
          path: "",
          component: Home,
        },
        {
          path: "/pages/:slug",
          component: PageDetailed,
        },
        {
          path: "/studios/:slug",
          component: StudioDetailed,
        },
        {
          path: "/agencies/:slug",
          component: AgencyDetailed,
        },
        {
          path: "/services/:slug",
          component: ServicesDetailed,
        },
      ],
    },
    { path: "/about", component: About },
  ],
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  linkActiveClass: "active",
});
