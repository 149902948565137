<template>
  <PageTemplate :details="serviceDetails" />
</template>

<script>
import PageTemplate from "../components/PageTemplate.vue";
export default {
  components: {
    PageTemplate,
  },

  computed: {
    serviceDetails() {
      return this.$store.state.services.serviceDetails;
    },
  },
  watch: {
    '$route.params.slug'() {
      this.fetchData()
    }
  },
  methods: {
    async fetchData() {
      const slug = this.$route.params.slug;
    await this.$store.dispatch("services/GET_SERVICES_DETAILS", slug);
    }
  },
  mounted() {
    this.fetchData()
  },
};
</script>

<style></style>
