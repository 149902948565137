<template>
  <div>
    <studio v-if="studios && studios.length > 0"/>
    <creativeagency />
  </div>
</template>

<script>
import Studio from "../components/Studio.vue";
import creativeagency from "../components/CreativeAgency.vue";

export default {
  name: "home",
  components: {
    Studio,
    creativeagency,
  },
  data() {
    return {};
  },
  computed: {
     studios() {
      return this.$store.state.studio.studioShow
    }
  },
  watch: {
    '$route'() {
      if(this.$route.hash){
        setTimeout(() => {
          document.querySelector(this.$route.hash).scrollIntoView()
        }, 1);
      }
    }
  },
  async mounted() {
    await this.$store.dispatch('studio/GET_STUDIOS_SHOW');
    if(this.$route.hash){
      document.querySelector(this.$route.hash).scrollIntoView()
    }

  }
};
</script>

<style scoped>

</style>
