<template>
  <div id="services">
    <div class="container">
      <div class="title">
        <h1>SERVICES</h1>
      </div>
    </div>
    <swiper :options="swiperOption">
      <swiper-slide v-for="service in services" :key="service.id">
        <div class="items-container">
          <router-link :to="`/services/${service.slug}`">
            <img :src="url + service.image" alt="" />
            <p class="name"> {{service.title }} </p>
          </router-link>
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
export default {
  name: "services",
  components: {},
  data() {
    return {
      swiperOption: {
        slidesPerView: 3,
        slidesPerGroup: 1,
        centeredSlides: false,
        // spaceBetween: 30,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 2,
            slidesPerGroup: 2,
          },
          768: {
            slidesPerView: 2,
            slidesPerGroup: 2,
          },
          640: {
            slidesPerView: 2,
            slidesPerGroup: 2,
          },
          320: {
            slidesPerView: 2,
            slidesPerGroup: 2,
          },
        },
      },
    };
  },
  computed: {
    url() {
      return this.$store.state.url;
    },
    services() {
      return this.$store.state.services.services;
    },
  },
  mounted() {
    this.$store.dispatch("services/GET_SERVICES");
  },
};
</script>

<style scoped>
::v-deep .swiper-pagination-bullet:only-child {
  visibility: hidden;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0;
  width: 100%;
  left: inherit;
}

::v-deep .swiper-pagination-bullet {
  width: 18px;
  height: 18px;
  opacity: 1;
  border-radius: 7px;
}
::v-deep .swiper-container {
  padding-bottom: 120px;
}

::v-deep .swiper-pagination-bullet {
  background: #000000;
}
::v-deep .swiper-pagination-bullet-active {
  opacity: 1;
  background: #ff0000;
}
.items-container a {
  color: inherit;
  text-decoration: none;
}
.items-container img:not(.hover) {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 150px;
}

.title h1 {
  font-family: "Calibri-Bold";
  font-size: calc(16px + (171 - 16) * ((100vw - 340px) / (1920 - 320)));
}
.items-container > div:not(.price, .hover) {
  display: flex;
  position: relative;
  align-items: center;
  width: 28%;
  cursor: pointer;
}
.items-container a:hover > .price {
  color: #ff0000;
}
.items-container {
  position: relative;
  cursor: pointer;
  width: 80% !important;
  margin-left: 10%;
  height: 410px;
  transition: all 0.5s linear;
}
.items-container div img:not(.hover) {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.name {
  font-family: "Calibri-Bold";
  margin-top: 10px;
  font-size: calc(16px + (44.36 - 16) * ((100vw - 340px) / (1920 - 320)));
  /* font-size: 44.36px; */
  color: #000000;
  font-weight: bold;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

div:hover > .price {
  color: red;
  transition: 0.3s;
}
a:hover > .hover {
  opacity: 1;
  transition: 0.3s;
}
.hover {
  width: 81px;
  height: 81px;
  padding: 10px;
  border: 2px solid #fff;
  position: absolute;
  background-color: red;
  top: 50%;
  left: 50%;
  border-radius: 43%;
  transform: translate(-50%, -60%) !important;
  opacity: 0;
}

@media screen and (min-width: 2000px) {
  .title h1 {
    font-size: 171px;
  }
}

@media screen and (max-width: 1024px) {
  .items-container {
    height: 380px;
    transition: all 0.5s linear;
  }
}

@media screen and (max-width: 768px) {
  ::v-deep .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    border-radius: 3px;
  }
  .hover {
    width: 30px;
    height: 30px;
  }
  .items-container {
    height: 280px;
    transition: all 0.5s linear;
  }
  .container {
    margin-top: 50px;
  }
  .slick-slider {
    margin-top: 30px;
  }
}

@media screen and (max-width: 480px) {
  .items-container {
    height: 180px;
    transition: all 0.5s linear;
  }
}
</style>
