<template>
  <PageTemplate :details="agencyDetails" />
</template>

<script>
import PageTemplate from "../components/PageTemplate.vue";
export default {
  components: {
    PageTemplate,
  },

  computed: {
    agencyDetails() {
      return this.$store.state.agencies.agencyDetails;
    },
  },
  async mounted() {
    const slug = this.$route.params.slug;
    await this.$store.dispatch("agencies/GET_AGENCY_DETAILS", slug);
  },
};
</script>

<style></style>
