<template>
  <footer id="footer">
    <div class="logo">
      <div class="con">
        <router-link to="/"
          ><img alt="Vue logo" src="../assets/logogu.png"
        /></router-link>
      </div>
      <h1>CONTACT</h1>
    </div>
    <div class="contact">
      <div class="emai">
        <a :href="'mailto:' + contact.email"> {{ contact.email }}</a>
      </div>
      <div class="phone">
        {{ contact.phone }}
      </div>
      <div class="adress">
        {{ contact.address }}
      </div>
      <div class="city">
        {{ contact.address2 }}
      </div>
    </div>
    <div class="footer-logos">
      <div class="left"></div>
      <div class="right">
        <a :href="social.facebook" target="_blank">
          <div class="fb"></div>
        </a>
        <a :href="social.instagram" target="_blank">
          <div class="instagram"></div>
        </a>
        <a :href="social.youtube" target="_blank">
          <div class="youtube"></div>
        </a>
        <a :href="social.twitter" target="_blank">
          <div class="twitter"></div>
        </a>
        <a :href="social.tiktok" target="_blank">
          <div class="tiktok"></div>
        </a>
      </div>
    </div>
    <div class="copyright">
      All Rights Reserved
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    social() {
      return this.$store.state.social.socialLinks;
    },
    contact() {
      return this.$store.state.social.contact;
    },
  },
  mounted() {
    this.$store.dispatch("social/GET_SOCIAL");
    this.$store.dispatch("social/GET_CONTACT");
  },
};
</script>

<style scoped>
footer {
  position: relative;
  top: 100px;
  width: 80%;
  margin: 0 auto;
}

.logo {
  display: flex;
  align-items: center;
}
.con {
  margin-right: 30px;
  width: 120px;
  height: 52px;
}
.con img {
  width: 100%;
}
h1 {
  font-family: "Calibri-Bold";
  font-size: calc(16px + (54 - 16) * ((100vw - 340px) / (1920 - 320)));
  line-height: 66px;
}
.contact {
  font-family: "Calibri-Regular";
  font-weight: bold;
  margin-top: 23px;
  font-size: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.emai a {
  color: inherit;
  text-decoration: none;
}

.footer-logos {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}
.right {
  display: flex;
  flex-wrap: wrap;
}
.right div {
  cursor: pointer;
}
.right a:not(:last-child) {
  margin-right: 25px;
}
.copyright {
  font-family: "Calibri-Regular";
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  padding-bottom: 50px;
  font-size: calc(16px + (28 - 16) * ((100vw - 340px) / (1920 - 320)));
  color: #ff0000;
}
.emai,
.phone,
.adress,
.city {
  font-size: calc(16px + (25 - 16) * ((100vw - 340px) / (1920 - 320)));
}
.fb,
.instagram,
.twitter,
.youtube,
.tiktok {
  width: 60px;
  height: 60px;
}

.fb {
  background-image: url("../assets/img/fbsvg.svg");
}
.fb:hover {
  background-image: url("../assets/img/fbsvgred.svg");
}
.instagram {
  background-image: url("../assets/img/instagramsvg.svg");
}
.instagram:hover {
  background-image: url("../assets/img/instagramsvgred.svg");
}
.youtube {
  background-image: url("../assets/img/youtubesvg.svg");
}
.youtube:hover {
  background-image: url("../assets/img/youtubesvgred.svg");
}
.youtube {
  background-image: url("../assets/img/youtubesvg.svg");
}
.youtube:hover {
  background-image: url("../assets/img/youtubesvgred.svg");
}
.twitter {
  background-image: url("../assets/img/twitersvg.svg");
}
.twitter:hover {
  background-image: url("../assets/img/twitersvgred.svg");
}
.tiktok {
  background-image: url("../assets/img/tiktok.svg");
}
.tiktok:hover {
  background-image: url("../assets/img/tiktokred.svg");
}

.right a {
  margin-top: 10px;
}

@media screen and (min-width: 2000px) {
  h1 {
    font-size: 54px;
  }
  .emai, .phone, .adress, .city {
    font-size: 25px;
  }
  .copyright {
    font-size: 28px;
  }
}

@media screen and (max-width: 600px) {
  .contact {
    display: inherit;
  }
  .fb,
  .instagram,
  .twitter,
  .youtube,
  .tiktok {
    width: 40px;
    height: 40px;
    background-size: contain;
  }
  .footer-logos {
    justify-content: flex-start;
  }
  .right a:not(:last-child) {
    margin-right: 10px;
  }
}
</style>
