<template>
  <PageTemplate :details="studioDetails" />
</template>

<script>
import PageTemplate from "../components/PageTemplate.vue";
export default {
  components: {
    PageTemplate,
  },

  computed: {
    studioDetails() {
      return this.$store.state.studio.studioDetails;
    },
  },
  async mounted() {
    const slug = this.$route.params.slug;
    await this.$store.dispatch("studio/GET_STUDIO_DETAILS", slug);
  },
};
</script>

<style></style>
