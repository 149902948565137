import axios from '../../utils/axios';

export default {
  namespaced: true,
  state() {
    return {
        socialLinks: {},
        contact:{},
        openGraph: {}
    }
  },
  mutations: {
    SET_SOCIAL(state, data) {
        state.socialLinks = data;
        // console.log(state.socialLinks);
      },
      SET_OPEN_GRAPH(state, data) {
        state.openGraph = data;
        // console.log(state.socialLinks);
      },
    SET_CONTACT(state, data) {
      state.contact = data;
      // console.log(state.contact)
    }
  },
  actions: {
    async GET_SOCIAL({commit}) {
        try {
          const res = await axios.get('/social')
          commit('SET_SOCIAL', res.data)
      } catch (error) {
          console.log(error)
        }
      },
    async GET_CONTACT({commit}) {
      try {
        const res = await axios.get('/contact')
        commit('SET_CONTACT', res.data)
      } catch (error) {
        console.log(error)
      }
    },
    async GET_OPEN_GRAPH({commit}) {
      try {
        const res = await axios.get('/opengraph')
        commit('SET_OPEN_GRAPH', res.data)
      } catch (error) {
        console.log(error)
      }
    }
  }
}