import axios from "../../utils/axios";

export default {
  namespaced: true,
  state() {
    return {
        studioDetails: "",
        studioShow: [],
        studioMovie: [],
    };
  },
  mutations: {
    SET_STUDIOS_SHOW(state, data) {
      state.studioShow = data;
      // console.log(state.studiosShow);
    },
    SET_STUDIOS_MOVIE(state, data) {
      state.studioMovie = data;
      // console.log(state.studiosShow);
    },
    SET_STUDIO_DETAILS(state, data) {
      state.studioDetails = data;
    },
  },
  actions: {
    async GET_STUDIOS_SHOW({ commit }) {
      try {
        const res = await axios.get("/studios/?take=-1&type=show");
        commit("SET_STUDIOS_SHOW", res.data.data);
      } catch (error) {
        console.log(error);
      }
    },
    async GET_STUDIOS_MOVIE({ commit }) {
      try {
        const res = await axios.get("/studios/?take=-1&type=movie");
        commit("SET_STUDIOS_MOVIE", res.data.data);
      } catch (error) {
        console.log(error);
      }
    },
    async GET_STUDIO_DETAILS({ commit }, slug) {
      try {
        const res = await axios.get(`/studios/${slug}/details`);
        commit("SET_STUDIO_DETAILS", res.data);
      } catch (error) {
        console.log(error);
      }
      console.log(slug);
    },
  },
};
