<template>
  <div class="container">
    <div class="image-div">
      <div class="top-img">
        <CurvedBox
          class="top-img"
          variant="third"
          style="width: 100%; height: 100%; position: absolute; top:0; left: 0;"
        >
          <CurvedBox class="img" variant="third">
            <img
              :src="url + (isSlider ? details.image : details.innerImage)"
              alt=""
            />
          </CurvedBox>
        </CurvedBox>
      </div>
      <div class="logo">
        <img :src="url + details.logo" alt="" />
      </div>
    </div>
    <div class="details">
      <CurvedBox variant="second">
        <div class="about-details">
          <div class="about-title">
            <h1>{{ details.innerTitle }}</h1>
          </div>
          <div class="about-text" v-html="details.description">
            <p>
              {{ details.description }}
            </p>
          </div>

          <!-- <videoslider v-if="pages && pages.length > 0" class="as" :medias="pages" /> -->

          <div class="video-slider">
            <swiper :options="swiperOption">
              <swiper-slide v-for="media in details.mediaList" :key="media.key">
                <div class="youtube">
                  <CurvedBox class="ss" variant="first"> </CurvedBox>
                  <CurvedBox>
                    <youtube :video-id="media.url" ref="youtube"></youtube>
                    <img
                      v-if="media.type == 'image'"
                      :src="url + media.url"
                      alt=""
                    />
                  </CurvedBox>
                </div>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
        </div>
      </CurvedBox>
    </div>
  </div>
</template>

<script>
// import videoslider from "./VideoSlider.vue";
import CurvedBox from "./CurvedBox.vue";
import Vue from "vue";
import VueYoutube from "vue-youtube";
Vue.use(VueYoutube);
export default {
  name: "aboutdetails",
  props: {
    details: {
      type: Object,
      default: () => ({}),
    },
    isSlider: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    // videoslider,
    CurvedBox,
    // VueSlickCarousel,
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        centeredSlides: false,
        // spaceBetween: 30,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
    };
  },
  computed: {
    url() {
      return this.$store.state.url;
    },
    pages() {
      return this.$store.state.pages.pages;
    },
  },

  async mounted() {
    await this.$store.dispatch("pages/GET_PAGES");
  },
  metaInfo() {
    return {
      title: this.pageDetails.title,
    };
  },
};
</script>

<style scoped>
::v-deep .swiper-pagination-bullet:only-child {
  visibility: hidden;
}
::v-deep .swiper-pagination-bullet {
  width: 18px;
  height: 18px;
  opacity: 1;
  border-radius: 7px;
  background: #fff;
}
::v-deep .swiper-pagination-bullet-active {
  opacity: 1;
  background: #ff0000;
}
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 60px;
  width: 100%;
  left: inherit;
}
.details {
  /* width: 95%; */
  margin: 0 auto;
}

.image-div {
  position: absolute;
  top: -92px;
  display: flex;
  margin-left: 212px;
  transition: 0.15s all ease;
}

.logo {
  position: relative;
  z-index: 1;
  width: 374px;
  bottom: 0;
  /* margin-top: 70px; */
  margin-left: 39px;
}
.logo img {
  width: 100%;
}

.container {
  position: relative;
  margin-top: 300px;
}
.top-img {
  width: 574px;
  height: 574px;
  position: relative;
  /* top: 8%;
  left: 212px; */
  z-index: 1;
  transition: 0.15s all ease;
}
::v-deep .top-img .variant-3 {
  background: white;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: -2px;
}
.top-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
::v-deep .img > .variant-3 {
  width: 534px;
  height: 533px;
  position: absolute;
}
.about-details {
  background-color: #000000;
  position: relative;
  color: #fff;
  min-height: 900px;
}
.about-title {
  padding-top: 500px;
  display: flex;
  justify-content: flex-end;
  width: 93%;
  transition: 0.15s all ease;
}
.about-title h1 {
  font-family: "Calibri-Bold";
  font-size: calc(16px + (91 - 16) * ((100vw - 340px) / (1920 - 320)));
  color: #fff;
}
.about-text {
  width: 87%;
  margin: 0 auto;
  margin-top: 80px;
  position: relative;
  padding-bottom: 100px;
  text-align: justify;
}
::v-deep .about-text p {
  font-family: "Calibri-Regular";
  font-size: calc(16px + (25 - 16) * ((100vw - 340px) / (1920 - 320)));
  color: #fff;
}

::v-deep .about-text p a {
  color: #fff;
}

/* //////////////////////////////// */

::v-deep .ss > .variant-1 {
  position: relative;
  width: 100%;
  height: 650px;
  background: red;
  top: -17px;
}

::v-deep .variant-1 {
  position: absolute;
  top: 0;
  width: 97%;
}

::v-deep .variant-1 img {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.youtube {
  width: 87% !important;
  /* left: 6.5%; */
  position: relative;
  padding-bottom: 100px;
  margin: 0 auto;
  margin-top: 60px;
}

::v-deep .variant-1 {
  position: absolute;
  top: 0;
  width: 97%;
  height: 620px;
}
::v-deep iframe {
  height: 100%;
  width: 100%;
  position: absolute;
}

@media screen and (min-width: 2000px) {
  .about-title h1 {
    font-size: 91px;
  }
  ::v-deep .about-text p {
    font-size: 25px;
  }
}

/* @media screen and (max-width: 1366px) {
  .top-img {
    width: 480px;
    height: 480px;
  }
  ::v-deep .img > .variant-3 {
    width: 450px;
    height: 450px;
    position: absolute;
  }
  .about-title {
    padding-top: 260px;
  }
} */

@media screen and (max-width: 1125px) {
  /* .image-div {
    top: 10%;
  } */
  .top-img {
    width: 400px;
    height: 400px;
  }
  ::v-deep .img > .variant-3 {
    width: 370px;
    height: 370px;
    position: absolute;
  }
  /* .logo {
    max-width: 330px;
    margin-top: 50px;
  } */
  .image-div {
    margin-left: 180px;
    top: -87px;
  }
  .about-title {
    padding-top: 312px;
  }
}

@media screen and (max-width: 1024px) {

  .image-div {
    top: -62px;
  }
 
  .top-img {
    width: 360px;
    height: 360px;
  }
  ::v-deep .img > .variant-3 {
    width: 330px;
    height: 330px;
    position: absolute;
  }
  .logo {
    max-width: 290px;
  }
  ::v-deep .ss > .variant-1 {
    height: 550px;
  }
  ::v-deep .variant-1 {
    position: absolute;
    top: 0;
    width: 97%;
    height: 530px;
  }
}

@media screen and (max-width: 768px) {
  ::v-deep .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    border-radius: 3px;
  }
  .about-details {
    min-height: inherit;
  }
  .image-div {
    top: -38px;
    margin-left: 100px;
  }
  .top-img {
    width: 260px;
    height: 260px;
    top: 0;
  }
  ::v-deep .img > .variant-3 {
    width: 230px;
    height: 230px;
    position: absolute;
  }
  .logo {
    max-width: 200px;
  }
  .about-title {
    padding-top: 157px;
  }
  .about-text {
    margin-top: 24px;
  }
  ::v-deep .ss > .variant-1 {
    height: 450px;
  }
  ::v-deep .variant-1 {
    position: absolute;
    top: 0;
    width: 97%;
    height: 420px;
  }
  .youtube {
    margin-top: 0;
  }
  ::v-deep iframe {
    transform: scale(1.1);
  }
}

@media screen and (max-width: 640px) {
  .top-img {
    width: 145px;
    height: 145px;
    /* position: absolute; */
    top: inherit;
    left: 0;
    z-index: 1;
  }
  .image-div {
    top: 0;
    margin-left: 0;
    left: 40px;
  }
  ::v-deep .img > .variant-3 {
    width: 130px;
    height: 130px;
    position: absolute;
  }
  .about-text {
    padding-bottom: 50px;
  }
  .youtube {
    margin-top: 0;
  }
  ::v-deep .ss > .variant-1 {
    position: relative;
    width: 100%;
    height: 210px;
    background: red;
    top: -17px;
  }

  .about-title {
    display: flex;
    justify-content: flex-end;
    width: 88%;
  }
  .container {
    margin-top: 0;
  }
  .logo {
    max-width: 135px;
    top: -20px;
    margin-top: 0;
    margin-left: 20px;
  }
  ::v-deep .variant-1 {
    position: absolute;
    top: 0;
    width: 97%;
    height: 190px;
  }
  ::v-deep iframe {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>
