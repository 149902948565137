<template>
  <div class="slider-cont">
    <swiper :options="swiperOption">
      <swiper-slide v-for="slide in slides" :key="slide.id">
        <div class="container">
          <CurvedBox class="ssd" variant="first">
            <div class="bg" :style="{ background: slide.color }"></div>
          </CurvedBox>
          <!-- <div class="bottom-banner">
            <CurvedBox class="bb" variant="first">
              <p>
                Everywhere <br />
                <i class="st">you want to be!</i>
              </p>
            </CurvedBox>
          </div> -->
          <div class="banner-container">
            <div class="banner">
              <h1>
                {{ slide.title }}
              </h1>
              <li class="btn">
                <router-link
                  :to="`/pages/${slide.slug}`"
                  :style="{ color: slide.color }"
                  >see more</router-link
                >
              </li>
            </div>
            <div class="border-image">
              <CurvedBox
                variant="third"
                style="width: 100%; height: 100%; position: absolute; top:0; left: 0;"
              >
              </CurvedBox>

              <CurvedBox class="img" variant="third">
                <img :src="url + slide.image" alt="" />
              </CurvedBox>
            </div>
          </div>
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <div class="bottom-banner">
      <CurvedBox class="bb" variant="first">
        <p>
          The point of view
        </p>
      </CurvedBox>
    </div>
    <!-- <div class="background">
      <CurvedBox
        variant="third"
        style="width: 100%; height: 100%; position: absolute; top:0; left: 0;"
      >
      </CurvedBox>
    </div> -->
  </div>
</template>

<script>
import CurvedBox from "../components/CurvedBox.vue";
export default {
  name: "firstslider",
  props: {
    slides: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    CurvedBox,
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: "auto",
        centeredSlides: false,
        // spaceBetween: 30,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
    };
  },
  computed: {
    url() {
      return this.$store.state.url;
    },
  },
};
</script>

<style scoped>
.swiper-slide {
  padding-bottom: 145px;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 40px;
  right: 26%;
  width: auto;
  left: inherit;
}

::v-deep .swiper-pagination-bullet-active {
  opacity: 1;
  background: #ff0000;
}
::v-deep .swiper-pagination-bullet {
  width: 18px;
  height: 18px;
  opacity: 1;
  border-radius: 7px;
  outline: none;
}

::v-deep .variant-1 {
  position: absolute;
  background: black;
  height: 100%;
  z-index: -1;
  top: 0;
  width: 100%;
}
::v-deep .bg {
  position: absolute;
  background-color: #ff0000;
  width: 100%;
  height: 100%;
}

.ssd {
  height: 0;
}

::v-deep .bb > .variant-1 {
  width: auto;
  height: auto;
  background: black;
}
.slider-cont {
  position: relative;
}
.bottom-banner {
  width: 100%;
  position: absolute;
  color: white;
  bottom: 33%;
  left: 197px;
  z-index: 22;
}
.bottom-banner p {
  margin: 0;
  padding: 75px 52px;
  font-family: "Calibri-LightItalic";
  font-size: calc(16px + (73.5 - 16) * ((100vw - 340px) / (1920 - 320)));
}
::v-deep .variant-3 {
  background: black;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.border-image {
  width: 450px;
  height: 450px;
  position: absolute;
  top: 70px;
  right: -5%;
  transition: 0.15s all ease;
  z-index: 25;
}

.background {
  width: 450px;
  height: 450px;
  position: absolute;
  top: 60px;
  right: -5%;
  transition: 0.15s all ease;
  z-index: 22;
}

.border-image img {
  width: 100%;
}
::v-deep .img > .variant-3 {
  width: 408px;
  height: 408px;
  position: absolute;
  right: 0;
}
.img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.container {
  width: 90% !important;
  left: 3%;
  position: relative;
  height: 619px;
  transition: 0.15s all ease;
}

.banner-container {
  display: flex;
}

.banner {
  padding-top: 167px;
  padding-left: 112px;
  padding-bottom: 200px;
  color: #ffffff;
  max-width: 40%;
}
.banner h1 {
  font-family: "Calibri-Bold";
  font-size: calc(16px + (46 - 16) * ((100vw - 340px) / (1920 - 320)));
}
/* .st {
  font-size: calc(16px + (73.5 - 16) * ((100vw - 340px) / (1920 - 320)));
} */
button {
  font-family: "Calibri-Regular";
  font-weight: bold;
  margin-top: 30px;
  color: red;
  border-radius: 10px;
  padding: 10px;
  border: none;
  outline: none;
}
.btn {
  font-family: "Calibri-Regular";
  font-weight: bold;
  margin-top: 30px;
  list-style-type: none;
}
.btn a {
  text-decoration: none;
  color: red;
  border-radius: 10px;
  padding: 10px;
  background: #fff;
}

@media screen and (min-width: 2000px) {
  .banner h1 {
    font-size: 46px;
  }
  .bottom-banner p {
    font-size: 73px;
  }
}

@media screen and (max-width: 1280px) {
  .container {
    height: 520px;
  }
  .border-image {
    height: 400px;
    width: 400px;
  }
  ::v-deep .img > .variant-3 {
    width: 360px;
    height: 360px;
    position: absolute;
    right: 0;
  }
}

@media screen and (max-width: 768px) {
  .container {
    height: 300px;
  }
  .ssd {
    height: 0;
  }
  .banner-container {
    display: inherit;
  }
  .banner {
    max-width: 80%;
    padding: 48px 43px;
  }
  .border-image {
    width: 245px;
    height: 245px;
    position: absolute;
    top: inherit;
    right: 7%;
    margin-bottom: 0;
    bottom: -30%;
  }

  .swiper-slide {
    padding-bottom: 175px;
  }

  ::v-deep .img > .variant-3 {
    width: 210px;
    height: 210px;
    position: absolute;
    right: 0;
  }
  .bottom-banner {
    width: 50%;
    position: absolute;
    color: white;
    bottom: 25%;
    left: 42px;
  }
  .bottom-banner p {
    padding: 25px 20px;
  }
  ::v-deep .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    border-radius: 3px;
  }

  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 25px;
    right: 22%;
  }
}

@media screen and (max-width: 500px) {
  .container {
    height: 175px;
  }
  .banner-container {
    display: inherit;
  }
  .image-div {
    margin-left: 0;
  }

  .border-image {
    width: 145px;
    height: 145px;
    position: absolute;
    top: inherit;
    right: 7%;
    margin-bottom: 0;
    bottom: -40%;
  }

  ::v-deep .img > .variant-3 {
    width: 130px;
    height: 130px;
    position: absolute;
    right: 0;
  }
  .bottom-banner {
    position: absolute;
    color: white;
    bottom: 28%;
    left: 25px;
  }
  .bottom-banner p {
   padding: 18px 9px;
  }
  .swiper-slide {
    padding-bottom: 140px;
  }
}
</style>
