import Vue from "vue";
import Vuex from "vuex";
import slider from "./modules/slider.js";
import pages from "./modules/pages.js";
import social from "./modules/social.js";
import about from "./modules/about";
import studio from "./modules/studio";
import merch from "./modules/merch";
import agencies from "./modules/agencies";
import services from "./modules/services";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    social: social,
    slider: slider,
    about: about,
    studio: studio,
    merch: merch,
    agencies: agencies,
    pages: pages,
    services: services
  },
  state() {
    return {
      url: process.env.VUE_APP_STATIC_RESOURCES_URL,
    };
  },
});
