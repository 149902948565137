import axios from "../../utils/axios";

export default {
  namespaced: true,
  state() {
    return {
        serviceDetails: "",
        services: []
    };
  },
  mutations: {
    SET_SERVICES(state, data) {
      state.services = data;
      // console.log(data)
    },
    SET_SERVICES_DETAILS(state, data) {
      state.serviceDetails = data;
      // console.log(state.serviceDetails)
    },
  },
  actions: {
    async GET_SERVICES({ commit }) {
      try {
        const res = await axios.get("/services/?take=-1");
        commit("SET_SERVICES", res.data.data);
      } catch (error) {
        console.log(error);
      }
    },
    async GET_SERVICES_DETAILS({ commit }, slug) {
      try {
        const res = await axios.get(`/services/${slug}/details`);
        commit("SET_SERVICES_DETAILS", res.data);
      } catch (error) {
        console.log(error);
      }
      console.log(slug);
    },
  },
};
