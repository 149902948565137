<template>
  <div>
    <svg
      id="svg"
      width="1252"
      height="619"
      viewBox="0 0 1252 619"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <clipPath id="v1" clipPathUnits="objectBoundingBox">
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="
                M .017, .1729
                C .03, .0869, .0606, .03, .1159, .0214, .205 .0071, .3522, 0, .5, 0
                S .795 .0071, .8841, .0214
                C .9394, .03, .97, .0869, .983, .1729, .9945, .25, 1, .3748, 1, .5
                s -.0055, .25 -.017, .3271
                C .97, .9131, .9394, .97, .8841, .9786, .795, .9929, .6478, 1, .5, 1
                s -.295 -.0071 -.3841 -.0214
                C .0606, .97, .03, .9131, .017, .8271 .0055, .75, 0, .6252, 0, .5
                S .0055, .25, .017, .1729Z
              "
          />
        </clipPath>
        <clipPath id="v2" clipPathUnits="objectBoundingBox">
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="
                M .8271, .017
                C .9131, .03, .97, .0606, .9786, .1159, .993, .205, 1, .3522, 1, .5
                s -.007, .295 -.0214, .3841
                C .97, .9394, .9131, .97, .8271, .983, .75, .9945, .6252, 1, .5, 1
                s -.25 -.0055 -.3271 -.017
                C .0869, .97, .03, .9394, .0214, .8841 .0071, .795, 0, .6478, 0, .5
                S .0071, .205, .0214, .1159
                C .03, .0606, .0869, .03, .1729, .017, .25, .0055, .3748, 0, .5, 0
                S .75, .0055, .8271, .017
                Z
              "
          />
        </clipPath>
        <clipPath id="v3" clipPathUnits="objectBoundingBox">
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="
                M .42, .9994
                c -.157 -.006 -.2443 -.0336 -.3117 -.0983
                C .0492, .8446, .0189, .7743 .0052, .6619
                c -.0049 -.04 -.007 -.2244 -.0034 -.28
                C .011, .247, .04, .1671, .104, .1043, .1693, .04, .25, .0109, .3927 .0022, .416 .0007, .458, 0, .5, 0
                s .084 .0007, .1073 .0022
                C .75, .0109, .8307, .04, .896, .1043, .96, .1671, .989, .247, .9982, .3821
                c .0036, .0554 .0015, .24 -.0034, .28 -.0137, .1124 -.044, .1827 -.1028, .2392
                C .8246, .9658, .7373, .9934, .58, .9994
                c -.017 .0007 -.0472 .0007 -.08, 0
                s -.0633, 0 -.08, 0
                Z
              "
          />
        </clipPath>
      </defs>
    </svg>
    <div :class="{
      'variant-1': variant === 'first',
      'variant-2': variant === 'second', 
      'variant-3': variant === 'third'}">
        <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: "first"
    },
     
    slides: {
      type: Array,
      default : () => []
  },
  }
};
</script>

<style scoped>
.variant-1 {
position: relative;
clip-path: url(#v1);
-webkit-clip-path: url(#v1);
}

.variant-2 {
position: relative;
clip-path: url(#v2);
-webkit-clip-path: url(#v2);
}

.variant-3 {
position: relative;
clip-path: url(#v3);
-webkit-clip-path: url(#v3);
}

svg {
width: 0;
height: 0;
}
</style>
