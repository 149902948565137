<template>
  <div class="container">
    <div class="logo" :class="{ ablogo: isBurgerActive }">
      <router-link class="a" to="/">
        <img class="desklogo" alt="Vue logo" src="../assets/logogu.png" />
        <img
          class="mobilelogo"
          alt="Vue logo"
          src="../assets/img/mobilelogo.png"
        />
      </router-link>
    </div>
    <div class="nav-links" :class="{ activenav: isBurgerActive }">
      <CurvedBox :class="{ 'variant-2': isBurgerActive }">
        <ul>
          <li><router-link to="/about">about</router-link></li>
          <li><router-link to="/#studio">studio</router-link></li>
          <li><router-link to="/#agency">creative agency</router-link></li>
          <li><router-link to="/#services">services</router-link></li>
          <!-- <li><router-link to="/#merch">MERCH</router-link></li> -->
          <li><router-link to="/#footer">contact</router-link></li>
        </ul>
      </CurvedBox>
      <Footer v-if="isBurgerActive"></Footer>
    </div>
    <div id="burger-menu" :class="{ active: isBurgerActive }" @click="toggle">
      <div class="line1"></div>
      <div class="line2"></div>
      <div class="line3"></div>
    </div>
  </div>
</template>

<script>
import CurvedBox from "../components/CurvedBox.vue";
import Footer from "../components/Footer.vue";
export default {
  name: "navigation",
  components: {
    CurvedBox,
    Footer,
  },
  data() {
    return {
      isBurgerActive: false,
    };
  },
  watch: {
    $route() {
      this.isBurgerActive = false;
      document.documentElement.classList.remove("freeze");
      document.body.classList.remove("freeze");
    },
  },
  methods: {
    toggle() {
      this.isBurgerActive = !this.isBurgerActive;
      if (this.isBurgerActive) {
        document.documentElement.classList.add("freeze");
        document.body.classList.add("freeze");
      } else {
        document.documentElement.classList.remove("freeze");
        document.body.classList.remove("freeze");
      }
    },
  },
};
</script>

<style scoped>
::v-deep .variant-2 {
  width: 90%;
  margin: 0 auto;
  margin-top: 30px;
  background: #fff;
}
::v-deep .variant-1 {
  clip-path: none;
}

.mobilelogo {
  display: none;
}
#burger-menu {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  height: 0;
}

.line1,
.line2,
.line3 {
  width: 30px;
  height: 4px;
  background-color: black;
  margin: 2px 0;
  transition: 0.4s;
  border-radius: 50px;
}
.active .line1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
  background-color: #000;
}

.active .line2 {
  opacity: 0;
}

.active .line3 {
  -webkit-transform: rotate(45deg) translate(-4.5px, -3px);
  transform: rotate(45deg) translate(-5px, -3px);
  background-color: #000;
}
a {
  position: relative;
}
a.active:not(.a):before {
  content: "";
  width: 17px;
  height: 17px;
  background-color: red;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
}
a:not(.a)::before {
  content: "";
  width: 17px;
  height: 17px;
  background-color: red;
  position: absolute;
  top: -20px;
  left: 50%;
  border-radius: 5px;
  transform: translateX(-50%);
  opacity: 0;
}

li a:hover:before {
  opacity: 1;
  transition: 0.2s;
}

#studio {
  scroll-behavior: smooth;
  transition: 3s;
}
.container {
  width: 98%;
  display: flex;
  position: relative;
  top: 65px;
  /* justify-content: space-between; */
  z-index: 25;
}
.logo {
  margin-left: 200px;
  max-width: 288px;
  height: 131px;
  display: flex;
  align-items: center;
}
.desklogo {
  width: 100%;
}

.activenav.nav-links {
  height: 100%;
  width: 100%;
  position: fixed;
  text-align: center;
  background: #fff;
  transition: all 0.3s ease;
  z-index: 25;
  padding-top: 30px;
  top: 0;
  left: 0;
}

.activenav.nav-links ul {
  flex-direction: column;
}

.activenav.nav-links ul li {
  padding: 10px 0;
}
.nav-links {
  width: 100%;
}
.nav-links ul {
  display: flex;
  justify-content: flex-end;
}
li a {
  font-family: "Calibri-Regular";
  font-weight: bold;
}

li {
  list-style-type: none;
  padding: 0 20px;
}
li:last-child {
  padding-right: 0;
}

a {
  text-decoration: none;
  color: inherit;
  font-size: calc(16px + (30 - 16) * ((100vw - 340px) / (1920 - 320)));
  line-height: 26px;
}

::v-deep footer {
  display: none;
}
@media screen and (min-width: 2000px) {
  a {
    font-size: 30px;
  }
}

@media screen and (max-width: 1024px) {
  a {
    font-size: 35px;
  }
  .nav-links {
    height: 0;
    overflow: hidden;
  }
  .logo {
    margin-left: 130px;
  }
  #burger-menu {
    height: auto;
    position: absolute;
    z-index: 35;
    right: 20px;
  }
  .activenav.nav-links ul li {
    padding: 23px 0;
  }
  .activenav.nav-links ul li:first-child {
    padding-top: 100px;
  }
  .activenav.nav-links ul li:last-child {
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 768px) {
  .activenav.nav-links {
    padding-top: 35px;
  }
  /* a {
  font-size: 25px;
} */
  .activenav.nav-links ul li {
    padding: 20px 0;
  }
  ::v-deep footer {
    position: unset;
    display: block;
    margin-top: 30px;
  }
  ::v-deep .footer-logos {
    margin-top: 0;
  }
  ::v-deep #footer .logo,
  ::v-deep .contact,
  ::v-deep .copyright {
    display: none;
  }
  ::v-deep .footer-logos {
    justify-content: center;
  }
  ::v-deep .tiktok:hover {
    background-image: url("../assets/img/tiktokwhite.svg");
  }
  ::v-deep .twitter:hover {
    background-image: url("../assets/img/twitterwhite.svg");
  }
  ::v-deep .youtube:hover {
    background-image: url("../assets/img/youtubewhite.svg");
  }
  ::v-deep .fb:hover {
    background-image: url("../assets/img/fbwhite.svg");
  }
  ::v-deep .instagram:hover {
    background-image: url("../assets/img/instagramwhite.svg");
  }
  .ablogo {
    position: absolute;
    z-index: 30;
  }
  .active .line1 {
    background-color: #fff;
  }
  .active .line3 {
    background-color: #fff;
  }
  .activenav.nav-links {
    background: #ff0000;
  }
  .activenav.nav-links ul li:first-child {
    padding-top: 50px;
  }
  .activenav.nav-links ul li:last-child {
    padding-bottom: 50px;
  }
  img.desklogo {
    display: none;
  }
  img.mobilelogo {
    display: block;
  }
  .container {
    top: 35px;
  }
  .logo {
    margin-left: 33px;
    width: auto;
    height: auto;
  }
  ::v-deep .variant-2 {
    will-change: transform;
  }
}
</style>
