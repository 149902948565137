<template>
  <PageTemplate :details="pageDetails" :isSlider="true" />
</template>

<script>
import PageTemplate from "../components/PageTemplate.vue";
export default {
  components: {
    PageTemplate,
  },

  computed: {
    pageDetails() {
      return this.$store.state.pages.pageDetails;
    },
  },
  watch: {
    '$route.params.slug'() {
      this.fetchData()
    }
  },
  methods: {
    async fetchData() {
      const slug = this.$route.params.slug;
      await this.$store.dispatch("pages/GET_PAGE_DETAILS", slug);
    }
  },
  mounted() {
    this.fetchData()
  },

};
</script>

<style></style>
