<template>
  <div class="about-container">
    <div class="container">
      <CurvedBox class="aboutcurved" variant="first"></CurvedBox>
      <div class="banner-container">
        <div class="banner">
          <h1>{{ about.title }}</h1>
          <p>
            {{ about.shortDescription }}
          </p>
        </div>
        <div class="border-image">
          <CurvedBox
            variant="third"
            style="width: 100%; height: 100%; position: absolute; top:0; left: 0;"
          >
          </CurvedBox>

          <CurvedBox class="img" variant="third">
            <img :src="url + about.image" alt="" />
          </CurvedBox>
        </div>
      </div>
      <div class="bottom-img">
        <CurvedBox
          variant="third"
          style="width: 100%; height: 100%; position: absolute; top:0; left: 0;"
        >
        </CurvedBox>
        <CurvedBox class="botimg" variant="third">
          <img :src="url + about.image2" alt="" />
        </CurvedBox>
      </div>
    </div>
    <div class="about-title">
      <h1>{{ about.contentTitle }}</h1>
    </div>
    <div class="about-text" v-html="about.description">
      <p>
        {{ about.description }}
      </p>
    </div>
    <div class="youtube" v-show="about.video != ''">
      <CurvedBox class="ss" variant="first"> </CurvedBox>
      <CurvedBox>
        <youtube :video-id="about.video" ref="youtube"></youtube>
      </CurvedBox>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueYoutube from "vue-youtube";
Vue.use(VueYoutube);
import CurvedBox from "../components/CurvedBox.vue";
export default {
  name: "about",
  components: {
    CurvedBox,
  },
  data() {
    return {};
  },
  computed: {
    about() {
      return this.$store.state.about.aboutData;
    },
    url() {
      return this.$store.state.url;
    },
  },
  mounted() {
    this.$store.dispatch("about/GET_ABOUT");
  },
};
</script>

<style scoped>
::v-deep .aboutcurved {
  height: 0;
}
::v-deep .aboutcurved > .variant-1 {
  width: 100%;
  height: 100%;
  background-color: #ff0000;
  position: absolute;
  z-index: -1;
}
::v-deep .ss > .variant-1 {
  position: relative;
  width: 100%;
  height: 816px;
  background: red;
  top: -17px;
}

::v-deep .variant-1 {
  position: absolute;
  top: 0;
  width: 97%;
}
.youtube {
  width: 95%;
  margin: 0 auto;
  margin-top: 90px;
  position: relative;
}
.about-container {
  /* width: 90%;
  left: 3%; */
  position: relative;
}
.container {
  width: 90%;
  left: 3%;
  position: relative;
  height: 619px;
}

::v-deep .variant-3 {
  background: black;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.border-image {
  width: 450px;
  height: 450px;
  position: absolute;
  top: 70px;
  right: -5%;
}
.border-image img {
  width: 100%;
}
::v-deep .img > .variant-3 {
  width: 408px;
  height: 408px;
  position: absolute;
  right: 0;
}
::v-deep .botimg > .variant-3 {
  width: 305px;
  height: 305px;
  position: absolute;
  left: 0;
}
.img img,
.botimg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-container {
  display: flex;
  position: relative;
  padding-bottom: 70px;
}

.banner {
  padding-top: 135px;
  padding-left: 86px;
  /* padding-bottom: 200px; */
  color: #ffffff;
  max-width: 50%;
}
.banner h1 {
  font-family: "Calibri-Bold";
  font-size: calc(16px + (80 - 16) * ((100vw - 340px) / (1920 - 320)));
}
.banner p {
  font-family: "Calibri-Regular";
  font-size: calc(16px + (25 - 16) * ((100vw - 340px) / (1920 - 320)));
  margin-top: 30px;
}
.bottom-img {
  width: 339px;
  height: 339px;
  position: absolute;
  bottom: -30%;
  left: 220px;
}
.about-title {
  margin-top: 130px;
  display: flex;
  justify-content: flex-end;
  width: 93%;
}
.about-title h1 {
  font-family: "Calibri-Bold";
  font-size: calc(16px + (91 - 16) * ((100vw - 340px) / (1920 - 320)));
  color: #ff0000;
}
.about-text {
  width: 87%;
  margin: 0 auto;
  margin-top: 48px;
  position: relative;
  font-family: "Calibri-Regular";
  font-size: calc(16px + (25 - 16) * ((100vw - 340px) / (1920 - 320)));
  text-align: justify;
}
:v-deep .about-text p {
  font-family: "Calibri-Regular";
  font-size: calc(16px + (25 - 16) * ((100vw - 340px) / (1920 - 320)));
  color: #000000;
}
::v-deep iframe {
  width: 100%;
  height: 758px;
}

@media screen and (min-width: 2000px) {
  .banner h1 {
    font-size: 80px;
  }
  .banner p {
    font-size: 25px;
  }
  .about-title h1 {
    font-size: 91px;
  }
  .about-text {
    font-size: 25px;
  }
}

@media screen and (max-width: 1280px) {
  .container {
    height: 520px;
  }
  .banner {
    padding-top: 100px;
    padding-left: 100px;
  }
  .border-image {
    height: 400px;
    width: 400px;
    top: 45px;
  }
  ::v-deep .img > .variant-3 {
    width: 360px;
    height: 360px;
  }

  .bottom-img {
    width: 300px;
    height: 300px;
  }

  ::v-deep .botimg > .variant-3 {
    width: 275px;
    height: 275px;
  }
}

@media screen and (max-width: 768px) {
  ::v-deep .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
  }
  .border-image {
    width: 155px;
    height: 155px;
    bottom: -60%;
    top: inherit;
    right: 20px;
}
 ::v-deep .img > .variant-3 {
    width: 138px;
    height: 138px;
  }

  .banner {
    max-width: 100%;
    padding: 48px 43px;
  }
  .container {
    height: 300px;
  }
  .bottom-img {
    width: 130px;
    height: 130px;
    bottom: -60%;
    left: 10px;
  }
  ::v-deep .botimg > .variant-3 {
    width: 116px;
    height: 116px;
}

  /* .border-image {
    width: 245px;
    height: 245px;
    top: 10px;
  } */
  
  .banner p {
    margin-top: 5px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .about-title {
    margin-top: 40px;
  }
  ::v-deep .ss > .variant-1 {
    height: 450px;
  }
  ::v-deep iframe {
    height: 420px;
  }
  .about-title {
    margin-top: 140px;
  }
}

@media screen and (max-width: 600px) {
  .container {
    height: 175px;
  }
  .border-image {
    bottom: -20%;
  }
  .bottom-img {
    bottom: -90%;
  }
  .about-text {
    margin-top: 10px;
  }
  ::v-deep .ss > .variant-1 {
    height: 210px;
  }
  ::v-deep iframe {
    height: 195px;
  }
  .youtube {
    width: 90%;
    margin-top: 30px;
  }
}
</style>
