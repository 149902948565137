<template>
  <div class="studio">
    <CurvedBox variant="first" class="studio-title">
      <h1>studio</h1>
    </CurvedBox>
    <CurvedBox variant="second">
      <div class="studio-container" id="studio">
        <div class="shows">
          <h1>Digital Shows</h1>
          <swiper :options="swiperOption">
            <swiper-slide v-for="show in shows" :key="show.id">
              <div class="movie-items">
                <router-link :to="`/studios/${show.slug}`">
                  <img :src="url + show.image" alt="" />
                </router-link>
              </div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
        <div class="movies">
          <h1>Movies & Series</h1>
          <swiper :options="swiperOption">
            <swiper-slide v-for="movie in movies" :key="movie.key">
              <div class="movie-items">
                <router-link :to="`/studios/${movie.slug}`">
                  <img :src="url + movie.image" alt="" />
                </router-link>
              </div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </div>
    </CurvedBox>
  </div>
</template>
<script>
import CurvedBox from "../components/CurvedBox.vue";
export default {
  name: "studio",
  components: { CurvedBox },
  data() {
    return {
      swiperOption: {
        slidesPerView: 3,
        slidesPerGroup: 1,
        // centeredSlides: false,
        spaceBetween: 75,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 2,
            slidesPerGroup: 2,
          },
          768: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            slidesPerColumn: 2,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            slidesPerColumn: 2,
            spaceBetween: 30,
          },
          320: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            slidesPerColumn: 2,
            spaceBetween: 30,
          },
        },
      },
    };
  },
  computed: {
    url() {
      return this.$store.state.url;
    },
    shows() {
      return this.$store.state.studio.studioShow;
    },
    movies() {
      return this.$store.state.studio.studioMovie;
    },
  },
  mounted() {
    this.$store.dispatch("studio/GET_STUDIOS_SHOW");
    this.$store.dispatch("studio/GET_STUDIOS_MOVIE");
  },
};
</script>

<style scoped>
::v-deep .swiper-pagination-bullet:only-child {
  visibility: hidden;
}
/* ::v-deep .swiper-wrapper {
  width: 92%;
  margin: 0 auto;
} */

::v-deep .swiper-slide {
  display: flex;
  justify-content: center;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0;
  width: 100%;
  left: inherit;
}

::v-deep .swiper-pagination-bullet {
  width: 18px;
  height: 18px;
  opacity: 1;
  border-radius: 7px;
  outline: none;
}
::v-deep .swiper-container {
  padding-bottom: 50px;
}

::v-deep .swiper-pagination-bullet {
  background: #fff;
}
::v-deep .swiper-pagination-bullet-active {
  opacity: 1;
  background: #ff0000;
}

.studio {
  position: relative;
  margin-top: 190px;
}

::v-deep .studio-title > .variant-1 {
  background-color: red;
}
.studio-title h1 {
  padding: 50px 70px;
  font-family: "Calibri-Bold";
  font-size: calc(16px + (95 - 16) * ((100vw - 340px) / (1920 - 320)));
}

::v-deep .studio-title > svg {
  display: none;
}

.movie-items {
  width: 100%;
  height: 440px;
  padding-bottom: 10px;
}

.movie-items img {
  height: 100%;
  object-fit: cover;
  border-radius: 50px;
}
.movie-items img {
  border: 8px solid rgba(255, 0, 0, 0);
}
.movie-items img:hover {
  border: 8px solid rgba(255, 0, 0, 1);
  transition: all 0.1s;
}

.studio-container {
  background-color: #000000;
  margin: 0 auto;
  position: relative;
}
.flexable {
  display: flex;
  justify-content: center;
}
.shows {
  padding: 225px 125px;
  padding-bottom: 0;
}
.movies {
  padding: 0 125px;
}

.shows h1,
.movies h1 {
  font-family: "Calibri-Regular";
  color: #fff;
  font-size: calc(16px + (69 - 16) * ((100vw - 340px) / (1920 - 320)));
  padding-bottom: 50px;
}
.movies h1 {
  padding-top: 50px;
}

.show-items img,
.movie-items img {
  width: 100%;
  box-sizing: border-box;
}

.studio-title {
  color: #fff;
  position: absolute;
  right: 10%;
  top: -4.5%;
  text-transform: uppercase;
  z-index: 1;
}
.movies {
  padding-bottom: 200px;
}

@media screen and (min-width: 2000px) {
  .studio-title h1 {
    font-size: 95px;
  }
  .shows h1, .movies h1 {
    font-size: 69px;
  }
}

@media screen and (max-width: 1024px) {
 
}

@media screen and (max-width: 768px) {
  ::v-deep.swiper-container {
    padding-bottom: 30px;
  }
  ::v-deep .swiper-wrapper {
    width: auto;
    margin: 0 auto;
  }
  ::v-deep .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    border-radius: 3px;
  }
  .shows {
    padding: 125px 47px;
    padding-bottom: 0;
  }
  .movies {
    padding: 0 47px;
  }
  .movie-items {
    height: 380px;
    transition: all 0.5s linear;
    /* padding: 10px 20px; */
  }
  .movie-items img {
    width: 100%;
    border: none;
  }
  .movie-items img:hover {
    border: none;
  }
  .studio-title {
    right: 10%;
    top: -1%;
  }
  .studio-title h1 {
    padding: 21px 27px;
  }
  .studio {
    margin-top: 0;
  }
  .studio-container {
    width: 96%;
  }
}

@media screen and (max-width: 600px) {
  .movie-items {
    height: 280px;
    transition: all 0.5s linear;
  }
  .shows {
    padding-top: 60px;
  }
  .movies {
    padding-bottom: 50px;
    margin-top: 30px;
  }
  .movies h1 {
    padding-top: 0;
  }
  .movie-items img {
    border-radius: 20px;
  }
  .shows h1,
  .movies h1 {
    padding-bottom: 20px;
  }
}
@media screen and (max-width: 480px) {
  .movie-items {
    height: 180px;
    transition: all 0.5s linear;
  }
  .studio-title {
    top: -1%;
  }
}
</style>
