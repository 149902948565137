<template>
  <div>
    <FirstSlider :slides="pages"  />
    <router-view />
  </div>
</template>

<script>
import FirstSlider from "../components/FirstSlider.vue";

export default {
  name: "home",
  components: {
    FirstSlider,
  },
  computed: {
    pages() {
      return this.$store.state.pages.pages
    },
  },
 async mounted() {
    await this.$store.dispatch('pages/GET_PAGES');
  }
};
</script>

<style scoped>

</style>
