import axios from "../../utils/axios";

export default {
  namespaced: true,
  state() {
    return {
        agencyDetails: "",
        agencyVideos: [],
        agencyBranding: [],
    };
  },
  mutations: {
    SET_AGENCY_VIDEOS(state, data) {
      state.agencyVideos = data;
      
    },
    SET_AGENCY_BRANDING(state, data) {
      state.agencyBranding = data;
     
    },
    SET_AGENCY_DETAILS(state, data) {
      state.agencyDetails = data;
    },
  },
  actions: {
    async GET_AGENCY_VIDEOS({ commit }) {
      try {
        const res = await axios.get("/agencies/?take=-1&type=videoAdsAndCampaigns");
        commit("SET_AGENCY_VIDEOS", res.data.data);
      } catch (error) {
        console.log(error);
      }
    },
    async GET_AGENCY_BRANDING({ commit }) {
      try {
        const res = await axios.get("/agencies/?take=-1&type=digitalAndBranding");
        commit("SET_AGENCY_BRANDING", res.data.data);
      } catch (error) {
        console.log(error);
      }
    },
    async GET_AGENCY_DETAILS({ commit }, slug) {
      try {
        const res = await axios.get(`/agencies/${slug}/details`);
        commit("SET_AGENCY_DETAILS", res.data);
      } catch (error) {
        console.log(error);
      }
      console.log(slug);
    },
  },
};
