import axios from "../../utils/axios";
export default {
  namespaced: true,
  state() {
    return {
      pageDetails: "",
      pages: []
    };
  },
  mutations: {
    SET_PAGE_DETAILS(state, details) {
      state.pageDetails = details;
      // console.log(state.pageDetails);
    },
    SET_PAGES(state, details) {
        state.pages = details;
        // console.log(state.pages);
      },
  },
  actions: {
    async GET_PAGE_DETAILS({ commit }, slug) {
      try {
        const res = await axios.get(`/pages/${slug}/details`);
        commit("SET_PAGE_DETAILS", res.data);
      } catch (error) {
        console.log(error);
      }
      console.log(slug);
    },
    async GET_PAGES({ commit }) {
      try {
        const res = await axios.get(`/pages?take=-1`);
        commit("SET_PAGES", res.data.data);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
