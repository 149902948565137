import axios from '../../utils/axios';

export default {
    namespaced: true,
    state() {
        return {
            merches: []
        }
    },
    mutations: {
        SET_MERCH(state, data) {
            state.merches = data;
            // console.log(state.merches);
        }
    },
    actions: {
        async GET_MERCH({commit}) {
            try {
                const res = await axios.get('/merches');
                commit('SET_MERCH', res.data)
            } catch (error) {
                console.log(error);
            }
        }
    }
}