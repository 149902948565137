import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router.js'
import store from './store/index.js'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueMeta from 'vue-meta'
Vue.config.productionTip = false
Vue.use(Vuex)
Vue.use(VueAwesomeSwiper)
 
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

import 'swiper/dist/css/swiper.css'

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
