import axios from "../../utils/axios";

export default {
  namespaced: true,
  state() {
    return {
      aboutData: {},
    };
  },
  mutations: {
    SET_ABOUT(state, data) {
      state.aboutData = data;
      // console.log(state.aboutData);
    },
  },
  actions: {
    async GET_ABOUT({ commit }) {
      try {
        const res = await axios.get("/about");
        commit("SET_ABOUT", res.data);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
